<template>
  <div v-if="translations" class="mr-4">
    <h4 class="text-success">{{ $t(language.translationKey) }}</h4>

    <div v-if="baseTranslations && Object.keys(baseTranslations).length > 0">
      <div v-for="(value, key) in baseTranslations" :key="`${language.id}-${key}`" class="row mb-2">
        <div class="col-12">
          <input v-model="translations[key]" class="form-control custom-input" type="text" @input="handleChangeTranslation(key)"/>
        </div>
      </div>
    </div>
    <div v-else>
      <h4>Brak tłumaczeń.</h4>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {languageHelper} from "@/helpers/language-helper";
import {errorCatcher} from "@/helpers/error-catcher";

export default {
  props: ["language", "baseTranslations"],

  data() {
    return {
      translation: null,
      translations: null,
      updatedTranslations: new Set()
    }
  },

  methods: {
    async loadTranslations(language) {
      try {
        const {data} = await axios.get(`/translation/by-language-id/${language.id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.translation = data
        this.translations = languageHelper.transformJSONToMap(JSON.parse(data.translations));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    handleChangeTranslation(translationKey) {
      this.updatedTranslations.add(translationKey)
    },

    canUpdateTranslations() {
      return this.updatedTranslations.size !== 0;
    },

    async updateTranslations() {
      if (this.updatedTranslations.size === 0) {
        return
      }

      const translationsToCreateOrUpdate = {}
      const entries = Object.entries(this.translations);
      for (const [key, value] of entries) {
        if (this.updatedTranslations.has(key)) {
          translationsToCreateOrUpdate[key] = value
        }
      }

      const json = JSON.stringify({
        id: this.translation.id,
        translationsToCreateOrUpdate: translationsToCreateOrUpdate,
        translationsToRemove: []
      })

      try {
        await axios.post(`/translation`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.updatedTranslations.clear();
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  },

  async created() {
    await this.loadTranslations(this.language)
  }

}
</script>

<style scoped>
.custom-input {
  width: 350px !important;
}
</style>