<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import {paginationHelper} from "@/helpers/pagination-helper";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import LanguageTable from "@/components/language/language-table.vue";
import {languageHelper} from "@/helpers/language-helper";
import BaseLanguageKeyTable from "@/components/language/base-language-key-table.vue";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";

export default {

  components: {
    BaseLanguageKeyTable,
    LanguageTable,
    Layout,
    PageHeader
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,
      reloadTables: false,
      languages: null,
      baseTranslations: null,
      languageRefs: {},

      modals: {
        addTranslationKey: {
          visible: false,

          form: {
            keyBase: "",
            key: ""
          }
        }
      },

      filtering: {
        text: ""
      }
    };
  },

  validations: {
    modals: {
      addTranslationKey: {
        form: {
          key: { required }
        }
      }
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.translations.title'),
          active: true
        }
      ]
    },

    setLanguageRef(index) {
      return (ref) => {
        if (!ref) {
          return
        }

        const refName = `language_${index}`;
        this.$set(this.languageRefs, refName, ref);
      };
    },

    async loadLanguages() {
      try {
        const {data} = await axios.get(`/language`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.languages = data

        await this.loadBaseTranslations(this.languages.find(element => element.translationKey === "ecat.language.pl"))
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadBaseTranslations(language) {
      try {
        const {data} = await axios.get(`/translation/by-language-id/${language.id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.baseTranslations = languageHelper.transformJSONToMap(JSON.parse(data.translations));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async save() {
      let canUpdateTranslations = false
      for (const index in this.languages) {
        const ref = this.languageRefs[`language_${index}`]
        if (ref && !canUpdateTranslations) {
          canUpdateTranslations = ref.canUpdateTranslations()
        }
      }

      if (!canUpdateTranslations) {
        await Swal.fire("Błąd!", "Żadne tłumaczenie nie zostało zmienione!", "error");
        return
      }

      for (const index in this.languages) {
        const ref = this.languageRefs[`language_${index}`]
        if (ref) {
          await ref.updateTranslations()
        }
      }

      await Swal.fire("Sukces!", "Pomyślnie zaktualizowano tłumaczenia", "success")
    },

    openAddTranslationKey() {
      this.modals.addTranslationKey.visible = true
    },

    hideAddTranslationKey() {
      this.modals.addTranslationKey.visible = false
      this.modals.addTranslationKey.form.key = ""
      this.submitted = false
    },

    addNewTranslationKey() {
      this.submitted = true

      this.$v.modals.addTranslationKey.$touch();
      if (this.$v.modals.addTranslationKey.$invalid) {
        return;
      }

      const { keyBase, key } = this.modals.addTranslationKey.form
      if (this.baseTranslations[keyBase + key]) {
        Swal.fire("", "Nie można dodać takiego samego klucza ponieważ juz istnieje taki klucz!", "error");
        return
      }

      this.baseTranslations[keyBase + key] = ""
      this.reloadTables = !this.reloadTables
    },

    // filteredTranslations() {
    //   if (!this.filtering.text) {
    //     return this.baseTranslations
    //   }
    //
    //   return Object.entries(this.baseTranslations)
    //       // eslint-disable-next-line no-unused-vars
    //       .filter(([key, val]) => val.toLowerCase().includes(this.filtering.text.toLowerCase()))
    //       .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
    // }

  },

  async created() {
    await this.loadLanguages()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.translations.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
<!--            <div class="row d-flex justify-content-center mb-4">-->
<!--              <div class="col-12 col-lg-2">-->
<!--                <label class="text-center">Wyszukaj za pomocą frazy z języka polskiego:</label>-->
<!--                <input v-model="filtering.text" class="form-control" />-->
<!--              </div>-->
<!--            </div>-->

            <template v-if="languages && baseTranslations">
              <div class="text-center">
                <div class="container-xs" style="overflow-x: auto;">
                  <div class="d-flex">
                    <base-language-key-table :base-translations="baseTranslations" :key="`base-language-key-${reloadTables}`" />
                    <language-table v-for="(language, index) in languages" :ref="setLanguageRef(index)" :language="language" :base-translations="baseTranslations" :key="`language-${index}-${reloadTables}`" />
                  </div>
                </div>

                <div class="button-items py-4">
                  <b-button variant="success" @click="save">Zapisz</b-button>
                  <b-button variant="secondary" @click="openAddTranslationKey">Dodaj nową translację</b-button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.addTranslationKey.visible"
        title="Dodawanie nowej translacji"
        title-class="font-18"
        hide-footer
        @hide="hideAddTranslationKey"
        @esc="hideAddTranslationKey">
      <div class="form-group">
        <label>Podstawka klucza</label>
        <input v-model="modals.addTranslationKey.form.keyBase" class="form-control" />
      </div>

      <div class="form-group">
        <label>Klucz</label>
        <input v-model="modals.addTranslationKey.form.key" class="form-control" :class="{ 'is-invalid': submitted && $v.modals.addTranslationKey.form.key.$error }" />
        <div v-if="submitted && !$v.modals.addTranslationKey.form.key.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="text-right">
        <b-button @click="addNewTranslationKey" variant="success">Dodaj</b-button>
        <b-button class="ml-1" variant="danger" @click="hideAddTranslationKey">{{  $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

  </Layout>
</template>